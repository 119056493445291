import { HeadFC, PageProps, graphql } from "gatsby"
import HomeHelper from "../helpers/home-helper"
import { websiteDto } from "../../../utils/website-dto"
import { NewHorizonHomePageType } from "../helpers/new-horizon.types"



const IndexPage = ({
    data: {
        api: { website, business, user },
    },
}: PageProps<{ api: any }>) => {
    
    const businessPhoneNumber = business?.phones?.[0].phoneNumber;
    const userPhoneNumber = user?.phone?.phoneNumber;
    
    const businessEmail = business?.emails?.[0].email;
    const userEmail = user?.email?.email;
    
    console.log({business});
    const requiredData = websiteDto({
        ...website,
        email: businessEmail ? businessEmail : userEmail ?? "",
        phoneNumber: businessPhoneNumber ? businessPhoneNumber : userPhoneNumber ?? "",
        hideMyLocation: business?.hideLocation ?? false,
        businessName: business?.displayName ?? "",
        logoUrl: business?.logoImage?.url ?? undefined,
        shortDescription: business?.shortDescription ?? "",
        longDescription: business?.longDescription ?? "",
        qualifications: business?.qualifications ?? [],
        links: business?.links ?? [],
        location: business?.locations && business?.locations.length > 0 ? business?.locations[0] : undefined
    }) as unknown as NewHorizonHomePageType
    
    console.log({requiredData});
    return <HomeHelper {...requiredData} />
}
export const Head: HeadFC = () => <title>Home Page</title>

export default IndexPage

export const query = graphql`
    query ($businessId: String!, $userId: String!) {
        api {
            website(where: { businessId: $businessId }) {
                ...WebsiteInfo
            }
            business(where: { id: $businessId }) {
                ...BusinessInfo
            }
            user(where: { id: $userId }) {
                ...UserInfo
            }
        }
    }
`
